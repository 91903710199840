const columnsLevelAggregation = [
    {
        "Header": "Level Number",
        "accessor": "level_id"
    },
    {
        "Header": "Aggregation Level",
        "accessor": "level_description"
    },
    {
        "Header": "No of Series",
        "accessor": "no_of_series"
    },
    {
        "Header": "Values",
        "accessor": "values"
    }
]

export { columnsLevelAggregation}