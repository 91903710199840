import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CompanyLogo, ApplicationLogo } from '../../assets/index'
import UserService from '../../services/UserService'
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faSignIn, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { device } from '../../Utils/device'

const Nav = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
     
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

  })


  const QuationSite = 'https://quation.in/'

  const handleLogoutClickHandler = () => {
    sessionStorage.clear();
    UserService.doLogout({ redirectUri: process.env.REACT_APP_PUBLIC_URL })
  }

  return (
    <NavContainer>
      <header className={ isSticky && !UserService.isLoggedIn() ? "sticky" : ""} >
        <nav className='page-width'>
          <div className="logo-section">
            <img src={ApplicationLogo} alt='Quation' />
           
          </div>
          <div className="userinfo-section d-flex align-items-center">
            {
              UserService.isLoggedIn() ?
                <>
                  <Button disabled className='btn-custom custom-font'>
                    <i><FontAwesomeIcon icon={faUser} /></i> &nbsp; {UserService.getUsername()}
                  </Button>
                  <p> </p>
                  <Button onClick={handleLogoutClickHandler} className='btn-custom custom-font'>

                    Logout  &nbsp; <i><FontAwesomeIcon icon={faSignOutAlt} /></i>
                  </Button>
                </>
                :
                <>
                  <Button onClick={() => UserService.doLogin()} className='btn-custom custom-font' >
                    Login
                  </Button>
                  <Button onClick={() => UserService.doSignUp()} className="btn-custom custom-font">
                    Signup
                  </Button>
                </>
            }
          </div>
        </nav>
      </header>
    </NavContainer>
  )
}


const NavContainer = styled.div`  

header {
    height: fit-content;
    width: 100vw;
    z-index: 10;
    position:absolute;
    top:0;
    left:0;
    }

    nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        -webkit-user-select: none;
        user-select: none;
        background-color: transparent;
        margin: 0 auto;
        position: static;
        background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(236,142,193,1) 100%);
    }

    .logo-section {
        padding: 0.4rem;
    }

    .logo-section img {
        height: 4rem;
    }

    .quation-logo {
        filter: grayscale(100%);
        padding: 10px;
        margin-top: 4px;
        display: inline-block;
        justify-content: center;
    }

    .headline-section h1 {
        margin-bottom: 0px;
    }

    .userinfo-section {
        padding-right: 2rem;

        button {
            margin: 0px 1rem;
        }
    }

    .userinfo-section p {
        float: right;
    }

    .userinfo-section a {
        display: block;
        text-align: right;
        cursor: pointer;
        color: blue;
        text-decoration: underline;
    }

    button {
        font-size: 18px;
        width: max-content;
    }

    .sticky{
        position:fixed;
        top:0;
        left:0;
        background-color: white;
        z-index: 10;
}

@media screen and  (${device.tablet}) {

}

    
@media screen and  (${device.mobileL}) {  
    nav{
    flex-direction :column;
    justify-items:center;
    }

    .logo-section {
        text-align:center;
    }
    .userinfo-section {
      flex-direction:row;
      padding-right: 0px;
      padding-bottom:0.5rem;
      justify-content:center;
      button {
            font-size:12px;
            margin: 0 0.25rem ;
        }
    }
  }

`

export default Nav